module.exports = {
  siteTitle: 'Gianlu Developer', // <title>
  manifestName: 'Gianlu.Dev',
  manifestShortName: 'Gianlu.Dev', // max 12 characters
  manifestStartUrl: 'https://gianlu.dev/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Gianlu.dev',
  heading: 'Full Stack Web Developer',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'GitHub',
      url: 'https://github.com/GianluDeveloper',
    },
    {
      icon: 'fa-coffee',
      name: 'Mirror of my GitHub Repos',
      url: 'https://gianlu.dev/GianluDeveloper',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:web@gianlu.dev',
    },

    // {
    //   icon: 'fa-bell-o',
    //   name: 'Keep in touch',
    //   url: '#',
    // },
  ],
};
//<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="e00b84e3-97cd-4f30-b736-3cdeafdd02cd" data-blockingmode="auto" type="text/javascript"></script>
