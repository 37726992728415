import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <footer>
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { icon, name, url } = social;
          if (url == '#') {
            return (
              <li key={url}>
                <a
                  href={url}
                  className={`icon ${icon} onesignal-customlink-container`}
                >
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          } else if (name === 'Github') {
            return (
              <li key={url}>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className={`icon ${icon}`}
                >
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          } else {
            return (
              <li key={url}>
                <a href={url} className={`icon ${icon}`}>
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          }
        })}
      </ul>
    </footer>
  );
}
