import React, { useState } from 'react';
import config from '../../config';
import ScriptTag from 'react-script-tag';
import myCookiePolicy, { getCookie } from './Cookie';

export default function PageFooter() {
  const [cookieState, setCookieState] = useState(getCookie());
  return (
    <>
      <footer id="footer">
        <ul className="copyright">
          <li>
            <a href="/privacypolicy">Privacy Policy</a>
          </li>
          <li>&copy; {config.authorName} - All rights reserved</li>
        </ul>
      </footer>
      {cookieState ? (
        <div className="cookies">
          <p style={{ fontSize: '12px' }}>
            <span style={{ fontSize: '16px' }}>This website uses cookies</span>
            <br />
            <p style={{ fontSize: '12px' }}>
              We inform you that this site uses cookies to make sure our web
              site is user-friendly and to guarantee a high functionality of the
              webpage. By continuing to browse this website, you declare to
              accept the use of cookies.
            </p>
          </p>
          <div className="cookieAction">
            <button
              type="button"
              onClick={elem => {
                myCookiePolicy(true);
                setCookieState(false);
                if (typeof window !== 'undefined') {
                  const data = '{"cookiesAccept":true}';
                  window.navigator.sendBeacon('/cookiesAccepted', data);
                }
              }}
            >
              Accept
            </button>{' '}
            <button
              type="button"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.location.href = '/privacypolicy/';
                }
                // /privacypolicy/
              }}
            >
              More info
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
